import { css } from "@emotion/react";
import Link from "next/link";

import PageHead from "~/components/common/PageHead";
import { Layout } from "~/lib/client/layout";

const Page = () => {
  const Head = () => (
    <PageHead
      title="Page Not Found"
      ogDescription="このページは現在開発中です"
    />
  );
  return (
    <Layout layoutType={"YTLayout"}>
      <Head />
      <main css={rootStyle}>
        <h1>Page Not Found</h1>
        <Link
          href={"/"}
          style={{
            width: "100%",
            marginTop: "3rem",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "flex-end",
            textDecoration: "underline",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {"＞ "}
          </span>
          <span>トップページへ</span>
        </Link>
      </main>
    </Layout>
  );
};

const rootStyle = css`
  padding: 2rem;
`;

export default Page;
